<template>
  <section id="user-profile">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
      <profile :user="user" :not-loaded="notLoaded" @setLoading="setLoading($event)" @setUser="setUser($event)"
               @getUser="getUser()" @getMe="getMe()"/>
    </b-overlay>
  </section>
</template>

<script>
import { BAlert, BCard, BCardBody, BCarousel, BCarouselSlide, BCol, BImg, BOverlay, BRow } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { gtmTrackNewEvent, throwDefaultError } from '@/helpers/helpers'
import Profile from '@/components/Profile'

export default {
  components: {
    BAlert,
    BCard,
    BCardBody,
    BCarousel,
    BCarouselSlide,
    BCol,
    BImg,
    BOverlay,
    BRow,

    Profile
  },
  data() {
    return {
      id: null,
      loading: false,
      notLoaded: false,
      user: null,
    }
  },
  created() {
    this.id = this.$route.params.id

    if (this.id) {
      this.getUser()
    }

    // gtmTrackNewEvent('userdetail', {
    //   category: 'Avantajlar',
    //   action: 'UserDetail',
    //   label: this.id
    // })
  },
  watch: {
    $route: {
      handler (value) {
        if (this.id !== value.params.id) {
          this.id = value.params.id

          if (this.id) {
            this.getUser()
          }
        }
      }, deep: true
    }
  },
  methods: {
    getMe() {
      useJwt.getMe()
          .then(response => {
            this.$store.dispatch('auth/updateUser', response.data.body)
          })
    },
    getUser() {
      this.loading = true

      useJwt.getUser(this.id)
          .then(response => {
            try {
              this.user = response.data.body
            } catch (e) {
              throwDefaultError(this.$toast)
              this.notLoaded = true
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
            this.notLoaded = true
          })
          .finally(() => {
            this.loading = false
          })
    },
    setLoading($event) {
      this.loading = $event
    },
    setUser($event) {
      this.user = $event
    },
  }
}
</script>
